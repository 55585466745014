.html,
.body {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: sans-serif;
    background-color: #E7FFFF;
    height: 100vh;
    width: 100%;
}
.body{
  text-align: center;
  margin: auto
}
._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    /* border-bottom: solid 4px #28a745; */
    /* background-color: white; */
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}