body {
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  text-align: center;
  color: #777;
  /* position: fixed;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%); */
}

body h1 {
  font-weight: 300;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #000;
}

body h3 {
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
  color: #555;
}

body a {
  color: #06f;
}

.invoice-box {
  max-width: 600px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.title_case {
  text-transform: lowercase;
}

.title_case::first-letter {
  text-transform: uppercase;
}

/* Counter Css Start */
#countdown li {
  display: inline-block;
  font-size: 1em;
  list-style-type: none;
  padding: .1em;
}

#countdown li span {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  text-transform: lowercase;
}

#countdown li span::first-letter {
  text-transform: uppercase;
}


@media all and (max-width: 768px) {

  #countdown li {
    font-size: calc(1.125rem * var(--smaller));
  }

  #countdown li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}

/* Counter Css End */



.card-container {
  overflow: scroll;
  white-space: nowrap;
  width: 100%;
  max-width: 528px;
}


@media only screen and (max-width: 600px) {
  .card-container {
    max-width: calc(100vw - 70px);
  }
}