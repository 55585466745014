@keyframes flip {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(-180deg);
  }
}

.body {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,0.4);
  z-index: 1;
}

.loader {
  width: 65px;
  margin: auto;
}

.loader__image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader__coin {
  animation: flip .5s ease-in-out infinite alternate-reverse both;
}